/**
 * 滚动到顶部贴顶固定
 * @param  {object} param -
 * @param  {object} param.$pins - 需要固定的目标，jQuery 对象
 * @param  {object} param.$scrollY - 目标所在的滚动区域，默认为 $(window)，jQuery 对象
 * @param  {string} param.pinClass - 目标固定时的ClassName，默认为 'pin'
 */

gh.Pin = function(param) {

  if (!(this instanceof gh.Pin)) {
    return new gh.Pin(param);
  }

  var param = param || {};
  var $pins = param.$pin || $('[data-pin="pin"]');
  var $scrollY = param.$scrollY || $(window);
  var pinClass = param.pinClass || 'pin';

  $pins.each(function(index, element) {

    // TODO: 滚动到一定位置，刷新页面，进行一次初始操作

    var $pin = $(this);
    var offsetTop = $pin.offset().top;

    // 目标脱离文档流后占位，避免塌陷
    if ($pin.next('.pin__placeholder').length < 1) {
      $pin.after('<div class="pin__placeholder" style="height:' + 0 + 'px;"></div>');
    }

    var $placeholder = $pin.next('.pin__placeholder');

    /**
     * 转为数字类型
     * @param  {number|string} num
     */
    function convertNum(num) {
      if (num && !isNaN(parseFloat(num)) && isFinite(num)) {
        return parseInt(num, 10);
      }
    }

    // 提前或延迟触发
    var topPadding = 0;
    if ($pin.attr('data-top-padding') === "height") {
      topPadding = $pin.height() * -1;
    } else {
      topPadding = convertNum($pin.attr('data-top-padding')) || 0;
    }

    $scrollY.on('scroll', function() {
      // 到达触发“钉住”位置钉住: 页面滚动位置 + 偏移量 >= 目标坐标
      if ($scrollY.scrollTop() + topPadding > offsetTop) {
        $pin.addClass(pinClass);
        $placeholder.height($pin.height());
      } else {
        $pin.removeClass(pinClass);
        $placeholder.height(0);
      }
    });

  });
};