$(document).ready(function() {
  new WOW().init();

  // 滚动到顶部贴顶固定
  gh.headerPin();

  // 根据 Hash 定位页面位置
  gh.urlHash();

  // 首页海报切换效果
  gh.indexPoster4();

  // Team 模态框
  gh.leaderModal();
});