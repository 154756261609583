/**
 * 首页海报切换效果
 */
gh.indexPoster4 = function() {
  var $indexPoster = $("#js_indexPoster");

  if ($indexPoster.length) {
    var bannerWidth = 0;
    var bannerHeight = 0;

    // 宿主当前尺寸
    var $window = $(window);
    var windowWidth = $window.width();
    var windowHeight = $window.height();

    // 动态重置海报尺寸
    function posterSize() {

      if (windowWidth < 1000) {
        bannerWidth = 750;
        bannerHeight = 620;
      } else {
        bannerWidth = 1920;
        bannerHeight = 890;
      }

      $indexPoster.css({
        width: windowWidth,
        height: bannerHeight * (windowWidth / bannerWidth)
      });
      $(".index-poster__slide").css({
        width: windowWidth,
        height: bannerHeight * (windowWidth / bannerWidth)
      });
    }

    posterSize();

    // Swiper 4
    var slideContainer = new Swiper(".index-poster__container", {
      direction: "horizontal",
      autoHeight: true,
      loop: true,
      speed: 800,
      autoplay: {
        delay: 3500
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        bulletClass: "swiper-pagination-switch",
        bulletActiveClass: "swiper-active-switch",
        clickable: true
      }
    });

    $window.on("resize", function() {
      console.log('in');
      windowWidth = $window.width();

      if (windowWidth > 1920) {
        windowWidth = 1920;
      }

      posterSize();

      setTimeout(function() {
        // 修正切换交互
        slideContainer.update();
      }, 120);

    });
  }
};

gh.indexPoster = function() {
  // 海报
  var $indexPoster = $("#js_indexPoster");

  if ($indexPoster.length) {
    var $slideContainer = $indexPoster.children(".swiper-container");

    // 基准值
    var $banner = $slideContainer.find(".swiper-slide:nth-child(1)>img");
    var bannerWidth = $banner.attr("width");
    var bannerHeight = $banner.attr("height");

    // 宿主当前尺寸
    var $window = $(window);
    var windowWidth = $window.width();
    var windowHeight = $window.height();

    // 动态重置海报尺寸
    function posterSize() {
      $indexPoster.css({
        width: windowWidth,
        height: bannerHeight * (windowWidth / bannerWidth)
      });
    }

    posterSize();

    // 初始切换交互
    // Swiper 2
    var slideContainer = $slideContainer.swiper({
      mode: "horizontal",
      loop: true,
      speed: 800,
      autoplay: 3500,
      pagination: ".index-poster__pagination",
      paginationClickable: true
    });

    // 预留左右切换按钮事件
    // $('.arrow-left').on('click', function(e){
    //   e.preventDefault();
    //   indexPoster.swipePrev();
    // });

    // $('.arrow-right').on('click', function(e){
    //   e.preventDefault();
    //   indexPoster.swipeNext();
    // });

    /**
     * 自适应浏览器宽度，限定最大宽度 1920，限定最小宽度 1000
     */
    $window.on("resize", function() {
      windowWidth = $window.width();
      windowHeight = $window.height();

      if (windowWidth > 1920) {
        windowWidth = 1920;
        windowHeight = (bannerHeight * windowWidth) / bannerWidth;
      } else if (windowWidth < 1000) {
        windowWidth = 1000;
        windowHeight = (bannerHeight * windowWidth) / bannerWidth;
      }

      // windowWidth = windowWidth > 1920 ? 1920 : windowWidth < 1000 ? 1000 : windowWidth;
      // windowHeight = windowWidth > 1920 ? (bannerHeight * windowWidth / bannerWidth) : windowWidth < 1000 ? (bannerHeight * windowWidth / bannerWidth) : windowHeight;

      posterSize();

      // 修正切换交互
      // Swiper 2
      slideContainer.resizeFix();
    });
  }
};