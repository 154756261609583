/**
 * 弹出层
 */
gh.leaderModal = function() {

  var $leaderModal = $('.js_leaderModal[data-leader]');

  /**
   * 解析数据拼模板
   * @param  {object} data - 数据
   * @param  {number|string} id - 当前 Modal 项
   * @param  {} $avatar - 头像(建议从数据中配置，目前暂时从页面 Clone)
   */
  function parseData(data, id, $avatar) {
    $('#js_leaderModal').remove();
    var tplOpen = '<div class="modal leader-modal" id="js_leaderModal" style="display: none;"><a class="modal__close" href="#" rel="modal:close"><i class="iconfont icon-close"></i></a><div class="col-xs-6 col-sm-6 col-md-2 col-lg-2"><div class="leader-modal__avatar"></div></div><div class="col-xs-12 col-sm-12 col-md-10 col-lg-10"><div class="leader-modal__info">';
    var tplInner = '';
    var tplClose = '</div></div></div>'
    var tplMeta = '';
    for (var i = 0; i < data.length; i++) {
      if (data[i]['id'] == id) {
        for (var item in data[i]) {
          var _temp = data[i][item];
          if (Array.isArray(_temp)) {
            for (var k = 0; k < _temp.length; k++) {
              tplMeta += '<a href="' + _temp[k]['url'] + '" target="_blank"><i class="iconfont icon-' + _temp[k]['type'] + '"></i></a>';
            }
          }
        }
        tplInner += '<h5 class="leader-modal__name">' + data[i]['name'] + '</h5>' +
          '<h6 class="leader-modal__title">' + data[i]['title'] + '</h6>' +
          '<p class="leader-modal__introduce">' + data[i]['introduce'].replace(/\n/g, '<br />') + '</p>';
      }
    }
    $('body').append(tplOpen + tplInner + tplClose);
    $('#js_leaderModal .leader-modal__avatar').append($avatar);

    // Modal
    $('#js_leaderModal').modal({
      showClose: false
    });
  }

  if ($leaderModal.length) {

    $leaderModal.on('click', function() {
      var $this = $(this);
      var leaderID = $this.data('leader');
      var $avatar = $this.find('.avatar').clone();
      var data = teamData;
      if (data.length) {
        parseData(data, leaderID, $avatar)
      }
    });
  }
};