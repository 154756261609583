/**
 * 根据 Hash 定位页面位置
 */
gh.urlHash = function() {

  /**
   * 定位之后，删除浏览器 URL 中的 Hash，为了好看
   */
  function removeHash() {
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history) {
      history.pushState("", document.title, loc.pathname + loc.search);
    } else {
      // 通过存储页面当前的滚动偏移量来防止滚动
      scrollV = document.body.scrollTop;
      scrollH = document.body.scrollLeft;

      loc.hash = "";

      // 恢复滚动偏移，无闪烁
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  }

  /**
   * 根据 Hash 定位页面位置，页面在 section 层级上添加 data-section 属性与导航上的 data-page 属性对应
   * @param  {string} hash - 位置节点
   */
  function locationHash(hash) {
    // 这里需要根据导航固定之后的高度调整
    var pos = $('.section[data-section="' + hash + '"]').offset().top - $('#js_header').height() - 50;
    $("html,body").animate({
      scrollTop: pos
    }, 800, 'easeOutExpo', function() {});
  }

  // 页面载入时有 Hash 进行一次定位，需要一点延迟，DOM 重排
  setTimeout(function() {
    window.location.hash && locationHash(window.location.hash.substring(1));
    removeHash();
  }, 100);

  // 导航绑定点击
  $('.nav__item').on('click', 'a[data-section]', function(e) {
    var _pathname = window.location.pathname;
    // 如果是首页位置就不要修改 URL 直接进行定位
    if (_pathname.length === 0 || _pathname === "/" || _pathname.match(/^\/?index/)) {
      e.preventDefault();
    }
    locationHash($(this).data('section'));
  });

  // 根据路径，激活导航状态
  function setNavigation() {
    // create regexp to match current url pathname,
    // remove trailing slash if present as it could collide with the link in navigation in case trailing slash wasn't present there
    var path = decodeURIComponent(window.location.pathname.replace(/\/$/, ""));
    var activeClass = 'nav__item_active';

    $(".nav__item a[data-page]").each(function() {
      var $this = $(this);
      var $item = $this.parent('.nav__item');
      var regexHref = RegExp($this.data('page'));

      if (regexHref.test(path)) {
        $item.addClass(activeClass).siblings('.nav__item').removeClass(activeClass);
      }
    });
  }

  setNavigation();

};