/**
 * 滚动到顶部贴顶固定
 */

gh.headerPin = function() {

  var $pin = $('#js_header');
  var $wrapper = $pin.parent();
  var $scrollY = $(window);
  var pinClass = 'page__header_pin';
  var offsetTop = $pin.offset().top;
  var pinHeight = 88;

  /**
   * 转为数字类型
   * @param  {number|string} num
   */
  function convertNum(num) {
    if (num && !isNaN(parseFloat(num)) && isFinite(num)) {
      return parseInt(num, 10);
    }
  }

  // 提前或延迟触发
  var topPadding = 0;
  if ($pin.attr('data-top-padding') === "height") {
    topPadding = $pin.height() * -1;
  } else {
    topPadding = convertNum($pin.attr('data-top-padding')) || 0;
  }

  $scrollY.on('scroll', function() {
    // 到达触发“钉住”位置钉住: 页面滚动位置 + 偏移量 >= 目标坐标
    if ($scrollY.scrollTop() + topPadding > offsetTop) {
      $pin.addClass(pinClass);
      $wrapper.css({
        'padding-top': pinHeight
      });
    } else {
      $pin.removeClass(pinClass);
      $wrapper.css({
        'padding-top': 0
      });
    }
  });

};